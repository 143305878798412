<template>
  <div 
    class="check-grid" 
    :class="{ 'selected': isSelected }"
    @click="toggleSelection"
  >
    <div class="key-row">{{ item.title }}</div>
    <div class="value-row">{{ item.value }}</div>
  </div>
</template>

<script>
export default {
  name: 'CheckGrid',
  props: {
    item: {
      type: Object,
      required: true,
      validator: obj => obj.hasOwnProperty('name') && obj.hasOwnProperty('title') && obj.hasOwnProperty('value')
    }
  },
  data() {
    return {
      isSelected: false
    }
  },
  methods: {
    toggleSelection() {
      this.isSelected = !this.isSelected;
      this.$emit('selection-change', {
        item: this.item,
        selected: this.isSelected
      });
    }
  }
}
</script>

<style scoped>
.check-grid {
  border: 1px solid #e8e8e8;
  cursor: pointer;
  transition: all 0.3s;
  height: 100%;
}

.key-row {
  background-color: #fafafa;
  padding: 8px;
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  font-weight: bold;
}

.value-row {
  padding: 8px;
  text-align: center;
}

.selected {
  background-color: #e6f7ff;
}
</style>