<template>
  <div class="login-container">
    <div class="login-box">
      <h2>登录</h2>
      <div class="input-group">
        <input 
          type="text" 
          v-model="phone" 
          placeholder="请输入手机号"
          @input="validatePhone"
        >
        <button 
          @click="getVerificationCode" 
          :disabled="!isPhoneValid || countdown > 0"
        >
          {{ countdown > 0 ? `${countdown}秒后重试` : '获取验证码' }}
        </button>
      </div>
      <div class="error-message" v-if="phoneError">
        {{ phoneError }}
      </div>
      
      <div class="input-group">
        <input 
          type="text" 
          v-model="verificationCode" 
          placeholder="请输入验证码"
          @input="validateCode"
        >
      </div>
      
      <button 
        class="login-button" 
        @click="login"
        :disabled="!isCodeValid"
      >
        登录
      </button>
    </div>
  </div>
</template>

<script>
import { validateChinesePhone, validateVerificationCode } from '../utils/validators'
import axios from 'axios'
import { Message } from 'ant-design-vue';

export default {
  name: 'Login',
  data() {
    return {
      phone: '',
      verificationCode: '',
      phoneError: '',
      isPhoneValid: false,
      isCodeValid: false,
      countdown: 0,
      timer: null
    }
  },
  methods: {
    validatePhone() {
      this.isPhoneValid = validateChinesePhone(this.phone)
      this.phoneError = this.isPhoneValid ? '' : '请输入有效的中国大陆手机号'
    },
    validateCode() {
      this.isCodeValid = validateVerificationCode(this.verificationCode)
    },
    async getVerificationCode() {
      if (!this.isPhoneValid) return
      
      try {
        await axios.post('YOUR_API_URL/send-code', {
          phone: this.phone
        })
        
        this.countdown = 60
        this.startCountdown()
      } catch (error) {
        console.error('发送验证码失败:', error)
      }
    },
    startCountdown() {
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--
        } else {
          clearInterval(this.timer)
        }
      }, 1000)
    },
    async login() {
      if (!this.isPhoneValid || !this.isCodeValid) return
      console.log("code:", this.verificationCode)
      // if (this.verificationCode === '123678') {
      //   localStorage.setItem('isLoggedIn', 'true')
      //   this.$router.push('/home')
      //   return
      // }
      try {
        // const response = await axios.post('YOUR_API_URL/verify-code', {
        // const response = await axios.get('https://api.ip008.com/odds/test.php')
        const response = await axios.post('https://api.ip008.com/odds/login.php', {
          phone: this.phone,
          code: this.verificationCode
        })
        
        if (response.data.success) {
          localStorage.setItem('isLoggedIn', 'true')
          localStorage.setItem('userId', response.data.userId)
          // commit('SET_MATCHES', response.data)
          this.$router.push('/home')
        }
        else {
          console.log(response.data)
          // Message.error('登录失败，请重新输入验证码')
        }
      } catch (error) {
        console.error('登录失败:', error)
      }
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.login-box {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.input-group {
  margin-bottom: 20px;
  display: flex;
  gap: 10px;
}

input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.login-button {
  width: 100%;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}
</style>