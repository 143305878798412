export const defaultMatches = [
  {
    id: 1,
    homeTeam: '曼联',
    awayTeam: '利物浦',
    matchTime: '2024-01-20 20:00',
    oddsTables: [
      {
        publishTime: '2024-01-19 10:00',
        odds: {
          '1:0': 6.5,
          '2:0': 8.0,
          '2:1': 7.5,
          '胜': 2.1,
          '平': 3.2,
          '负': 2.8
        }
      },
      {
        publishTime: '2024-01-19 15:00',
        odds: {
          '1:0': 6.8,
          '2:0': 8.2,
          '2:1': 7.8,
          '胜': 2.2,
          '平': 3.3,
          '负': 2.7
        }
      }
    ]
  },
  {
    id: 2,
    homeTeam: '皇马',
    awayTeam: '巴萨',
    matchTime: '2024-01-21 21:00',
    oddsTables: [
      {
        publishTime: '2024-01-20 09:00',
        odds: {
          '1:0': 5.5,
          '2:0': 7.0,
          '2:1': 6.5,
          '胜': 1.9,
          '平': 3.4,
          '负': 3.0
        }
      }
    ]
  }
]