<template>
  <div class="check-table">
    <check-row
      v-for="(row, index) in data"
      :key="index"
      :col-count="row.colCount"
      :odds="row.odds"
      :title="row.title"
      @row-selection-change="handleRowSelectionChange"
    />
  </div>
</template>

<script>
import CheckRow from './CheckRow.vue';

export default {
  name: 'CheckTable',
  components: {
    CheckRow
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedItems: new Set()
    }
  },
  methods: {
    handleRowSelectionChange(event) {
      const { item, selected } = event;
      if (selected) {
        this.selectedItems.add(item);
      } else {
        this.selectedItems.delete(item);
      }
      this.$emit('table-selection-change', Array.from(this.selectedItems));
    }
  }
}
</script>

<style scoped>
.check-table {
  border: 1px solid #e8e8e8;
}
</style>