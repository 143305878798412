<template>
    <div class="checkbox-table">
      <checkbox-grid
        v-for="(row, index) in tableData"
        :key="index"
        :formData="row['odds']"
        :colCount="row['colCount']"
        @update:selected-data="handleSelectedData(index, $event)"
      />
    </div>
  </template>
  
  <script>
  import CheckboxGrid from './CheckboxGrid.vue';
  
  export default {
    components: {
      CheckboxGrid
    },
    props: {
      tableData: {
        type: Array,
        required: true
      },
      colCount: {
        type: Number,
        default: 4
      }
    },
    methods: {
      handleSelectedData(index, selectedData) {
        // 处理从CheckboxGrid发出的选择数据
        console.log(`Data from row ${index}:`, selectedData);
        // 可以在这里更新父组件的数据或执行其他操作
      }
    }
  };
  </script>
  
  <style scoped>
  .checkbox-table {
    display: flex;
    flex-direction: column;
  }
  </style>