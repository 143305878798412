<template>
    <div class="checkbox-grid">
      {{ formData }}
      <div class="row" v-for="(row, rowIndex) in formattedData" :key="rowIndex">
        <a-button
          class="item"
          type="dashed"
          style="white-space: normal;"
          v-for="(item, colIndex) in row"
          :key="colIndex"
          @click="toggleSelect(rowIndex, colIndex)"
          :class="{ selected: isSelected(rowIndex, colIndex) }"
        >
          {{ item }}
        </a-button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      // formData: {
      //   type: Array,
      //   required: true
      // },
      formData: {
        type: Object,
        required: true
      },
      colCount: {
        type: Number,
        default: 4
      }
    },
    computed: {
      formattedData() {
        const result = [];
        // const data = Object.values(this.formData)
        Object.keys(this.formData).forEach((key, index) => {
          const row = Math.floor(index / this.colCount);
          if (!result[row]) {
            result[row] = [];
          }
          result[row].push(key + "\n" + this.formData[key]);
        });
        return result;
      }
    },
    data() {
      return {
        selectedItems: []
      };
    },
    methods: {
      toggleSelect(rowIndex, colIndex) {
        const index = this.selectedItems.indexOf(`${rowIndex}-${colIndex}`);
        if (index > -1) {
          this.selectedItems.splice(index, 1);
        } else {
          this.selectedItems.push(`${rowIndex}-${colIndex}`);
        }
        this.$emit('update:selected-data', this.formatSelectedData());
      },
      isSelected(rowIndex, colIndex) {
        return this.selectedItems.includes(`${rowIndex}-${colIndex}`);
      },
      formatSelectedData() {
        return this.selectedItems.map(item => {
          const [rowIndex, colIndex] = item.split('-').map(Number);
          return this.formData[rowIndex * this.colCount + colIndex];
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .checkbox-grid {
    display: flex;
    flex-direction: column;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
  }
  .item {
    flex: 1 1 calc(100% / 4 - 8px); /* 默认每行4个，减去间隙 */
    margin: 1px;
  }
  .selected {
    background-color: #1890ff;
    color: white;
  }
  </style>