import axios from 'axios'
import { defaultMatches } from '../data/defaultData'

export default {
  namespaced: true,
  state: {
    userId: 0,
    list: [],
    currentMatch: null,
    currentOdds: null
  },
  mutations: {
    SET_USERID(state, id) {
      state.userId = id
    },
    SET_MATCHES(state, matches) {
      state.list = matches
    },
    SET_CURRENT_MATCH(state, match) {
      state.currentMatch = match
    },
    SET_CURRENT_ODDS(state, odds) {
      state.currentOdds = odds
    }
  },
  actions: {
    async fetchMatches({ commit }) {
      try {
        // const response = await axios.get('YOUR_API_URL/matches')
        const response = await axios.get('https://api.ip008.com/odds/matches.php')
        commit('SET_MATCHES', response.data)
      } catch (error) {
        console.error('Error fetching matches:', error)
        commit('SET_MATCHES', defaultMatches)
      }
    }
  },
  getters: {
    getMatchById: (state) => (id) => {
      return state.list.find(match => match.id === id)
    }
  }
}