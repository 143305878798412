<template>
  <div class="home-container">
    <div class="logout-section">
      <a href="#" @click.prevent="logout" class="logout-link">退出</a>
    </div>

    <div class="match-selection">
      <select v-model="selectedMatchId" @change="handleMatchChange">
        <option value="">选择比赛</option>
        <option v-for="match in matches" :key="match.id" :value="match.id">
          {{ match.homeTeam }} vs {{ match.awayTeam }} ({{ match.matchTime }})
        </option>
      </select>

      <select v-model="selectedOddsTime" @change="handleOddsChange">
        <option v-for="(table, index) in currentMatchOddsTables" 
                :key="index" 
                :value="table.publishTime">
          {{ table.publishTime }}
        </option>
      </select>
    </div>

    <div class="odds-list" v-if="currentOdds">
      <div v-for="(value, key) in currentOdds.odds" :key="key" class="odds-item">
        <label>
          <input type="checkbox" 
                 v-model="selectedOddsItems" 
                 :value="{ type: key, odds: value }">
          {{ key }} ({{ value }})
        </label>
      </div>
    </div>

    <div v-if="currentOdds">
      <!-- <div v-for="(title, odd_name) in oddNames" :key="odd_name">
        {{ title }}
        <ul class="odds-list">
          <div v-for="(value, key) in currentOdds[odd_name]" :key="key" class="odds-item">
            <label>
              <input type="checkbox" 
                    v-model="selectedOddsItems" 
                    :value="{ type: key, odds: value }">
              {{ key }} ({{ value }})
            </label>
          </div>
        </ul>
      </div> -->
      <div>
        <!-- {{ tableDataSet }} -->
        <!-- <checkbox-table
          :tableData="tableDataSet"
          :colCount="3"
          @update:selected-data="handleTableData"
        /> -->
        <check-table 
          :data="tableDataSet"
          @table-selection-change="handleTableSelectionChange"
        />

        <!-- <checkbox-grid :formData="currentOdds.had" @update:selected-data="handleDataSelection" /> -->
        <!-- <checkbox-grid :formData="formDataSet2" @update:selected-data="handleDataSelection2" /> -->
      </div>
    </div>

    <!-- <div class="recommendation-buttons">
      <button @click="applyRecommendation1">推荐方案1</button>
      <button @click="applyRecommendation2">推荐方案2</button>
    </div> -->

    <div class="betting-table-section" v-if="selectedOddsItems.length">
      <h3>投注表 ({{ currentMatch.homeTeam }} vs {{ currentMatch.awayTeam }})</h3>
      <table class="betting-table" v-if="selectedOddsItems.length">
        <thead>
          <tr>
            <th>比分</th>
            <th>赔率</th>
            <th>投注</th>
            <th>收益</th>
            <th>收益减成本</th>
            <th>收益率</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in selectedOddsItems" :key="index">
            <!-- <td>{{ item.type }}</td>
            <td>{{ item.odds }}</td> -->
            <td>{{ item.title }}</td>
            <td>{{ item.value }}</td>
            <td>
              <input type="number" v-model.number="bettingAmounts[index]" @input="calculateResults">
            </td>
            <td>{{ calculateReturn(item.value, bettingAmounts[index]) }}</td>
            <td>{{ calculateNetReturn(item.value, bettingAmounts[index]) }}</td>
            <td>{{ calculateROI(item.value, bettingAmounts[index]) }}%</td>
          </tr>
          <tr class="total-row">
            <td colspan="2">总计</td>
            <td>{{ totalBetting }}</td>
            <td colspan="3"></td>
          </tr>
        </tbody>
      </table>

      <button class="save-button" @click="saveBettingData" v-if="selectedOddsItems.length">
        保存
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import CheckboxTable from '../components/CheckboxTable.vue'
import CheckTable from '../components/CheckTable.vue';

export default {
  name: 'Home',
  components: {
    CheckTable,
    // 'a-button': Button,
    CheckboxTable
  },
  data() {
    return {
      selectedMatchId: '',
      selectedOddsTime: '',
      selectedOddsItems: [],
      bettingAmounts: [],
      oddNames: {'had':'胜负', 'crsh':'主队胜', 'crsd':'主队平', 'crsa':'主队负', 'ttg':'总进球数', 'hafu':'半全场胜平负'}
    }
  },
  computed: {
    userId() {
      return localStorage.getItem('userId')
    },
    matches() {
      return this.$store.state.matches.list
    },
    currentMatch() {
      return this.$store.getters['matches/getMatchById'](this.selectedMatchId)
    },
    currentMatchOddsTables() {
      return this.currentMatch?.oddsTables || []
    },
    currentOdds() {
      return this.currentMatchOddsTables.find(t => t.publishTime === this.selectedOddsTime)
    },
    totalBetting() {
      return this.bettingAmounts.reduce((sum, amount) => sum + (amount || 0), 0)
    },
    tableDataSet() {
      if (this.currentOdds) {
        return [{"colCount": 3, "odds": this.currentOdds.had, "title": "胜平负"},
                {"colCount":6, "odds": this.currentOdds.crsh, "title": "主队胜"},
                {"colCount":6, "odds": this.currentOdds.crsd, "title": "主队平"},
                {"colCount":6, "odds": this.currentOdds.crsa, "title": "主队负"},
                {"colCount":4, "odds": this.currentOdds.ttg, "title": "总进球数"},
                {"colCount":3, "odds": this.currentOdds.hafu, "title": "半全场胜平负"}
              ]
      }
      return []
    }
  },
  methods: {
    async fetchInitialData() {
      await this.$store.dispatch('matches/fetchMatches')
    },
    handleMatchChange() {
      if (this.currentMatch && this.currentMatchOddsTables.length) {
        this.selectedOddsTime = this.currentMatchOddsTables[this.currentMatchOddsTables.length - 1].publishTime
        this.selectedOddsItems = []
        this.bettingAmounts = []
      }
    },
    handleOddsChange() {
      this.selectedOddsItems = []
      this.bettingAmounts = []
    },
    applyRecommendation1() {
      if (this.currentOdds) {
        const firstKey = Object.keys(this.currentOdds.odds)[0]
        this.selectedOddsItems = [{
          type: firstKey,
          odds: this.currentOdds.odds[firstKey]
        }]
        this.bettingAmounts = [0]
      }
    },
    applyRecommendation2() {
      if (this.currentOdds) {
        const secondKey = Object.keys(this.currentOdds.odds)[1]
        this.selectedOddsItems = [{
          type: secondKey,
          odds: this.currentOdds.odds[secondKey]
        }]
        this.bettingAmounts = [0]
      }
    },
    handleTableData(data) {
      // this.selectedData1 = data;
      this.selectedOddsItems = data;
      console.log(data)
    },
    handleTableSelectionChange(items) {
      this.selectedOddsItems = items;
      console.log("handleTableSelectionChange", items)
    },
    calculateReturn(odds, amount) {
      return ((odds * amount) || 0).toFixed(2)
    },
    calculateNetReturn(odds, amount) {
      return (((odds * amount) - this.totalBetting) || 0).toFixed(2)
    },
    calculateROI(odds, amount) {
      if (!amount) return '0.00'
      return (((odds * amount - this.totalBetting) / this.totalBetting) * 100).toFixed(2)
    },
    calculateResults() {
      this.bettingAmounts = [...this.bettingAmounts]
    },
    async saveBettingData() {
      console.log(this.selectedOddsItems)
      const bettingData = this.selectedOddsItems.map((item, index) => ({
        // type: item.type,
        // odds: item.odds,
        odds: item.name,
        // value: item.value,
        amount: this.bettingAmounts[index] || 0
      }))
      const data = { userId: this.userId, matchId: this.selectedMatchId, betting: bettingData }
      console.log("saving: ", data)

      try {
        await axios.post('https://api.ip008.com/odds/save.php', data)
        alert('保存成功')
      } catch (error) {
        console.error('保存失败:', error)
        alert('保存失败')
      }
    },
    logout() {
      localStorage.removeItem('isLoggedIn')
      this.$router.push('/')
    }
  },
  created() {
    this.fetchInitialData()
  }
}
</script>

<style scoped>
.home-container {
  padding: 0;
  max-width: 1200px;
  margin: 0 auto;
}

.logout-section {
  text-align: right;
  margin-bottom: 20px;
}

.logout-link {
  color: #666;
  text-decoration: none;
}

.match-selection {
  margin-bottom: 20px;
  display: flex;
  gap: 20px;
}

select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  min-width: 200px;
}

.odds-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  margin-bottom: 20px;
}

.odds-item {
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 4px;
}

.recommendation-buttons {
  margin-bottom: 20px;
}

.betting-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.betting-table th,
.betting-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.betting-table th {
  background-color: #f5f5f5;
}

.betting-table input {
  width: 100px;
  padding: 5px;
  text-align: right;
}

.total-row {
  background-color: #f9f9f9;
  font-weight: bold;
}

.betting-table-section h3 {
  font-weight: bold;
  margin-top: 10px;
}

.save-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.save-button:hover {
  background-color: #45a049;
}
</style>