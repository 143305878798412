<template>
  <div class="check-row">
    <h3 class="check-row__title">{{ title }}</h3>
    <a-row :gutter="0">
      <a-col 
        v-for="item in odds" 
        :key="item.name"
        :span="24 / colCount"
      >
        <check-grid 
          :item="item"
          @selection-change="handleSelectionChange"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { Row, Col } from 'ant-design-vue';
import CheckGrid from './CheckGrid.vue';

export default {
  name: 'CheckRow',
  components: {
    CheckGrid,
    'a-row': Row,
    'a-col': Col
  },
  props: {
    colCount: {
      type: Number,
      default: 3
    },
    odds: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleSelectionChange(event) {
      this.$emit('row-selection-change', event);
    }
  }
}
</script>

<style scoped>
.check-row {
  margin-bottom: 1px;
}
.check-row__title {
  margin-top: 10px;
  font-weight: bold;
}
</style>